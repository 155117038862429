import useBusinessUnitStore from '@/store/businessunit'

export default function ({ route, redirect }) {
    const { hasFilled3OnboardingState } = useBusinessUnitStore()

    if (!hasFilled3OnboardingState) {
        const { idOrga, idBU } = route.params
        redirect(`/organizations/${idOrga}/business-units/${idBU}/dashboard`)
    }
}
