export enum DialogType {
    Alert = 'alert',
    Confirm = 'confirm'
}

export enum DialogVariant {
    DELETE = 'delete',
    INFO = 'info',
    WARNING = 'warning'
}

export interface OptionChargemapSelect {
    id: number | string | null
    value: string
    blocked?: boolean
    raw?: any
}

export interface LocationChargemapSelect {
    id: number
    address: string
    city: string
    postalCode: string
    countryCode: string
    lat: number
    lng: number
}

export interface ControlInputInError {
    error: string
    id: number
}

export type CssClasses = string | { [k: string]: any } | Array<string>
