import { isValidPrice } from './validators'

export default function convertToPrice(rate: string | number | null): number {
    if (!rate || !isValidPrice(rate as string)) return 0
    if (typeof rate === 'number') return rate

    let rateTrimmed = rate.replace(/,/g, '.')
    if (rateTrimmed.startsWith('.')) {
        rateTrimmed = `0${rateTrimmed}`
    }

    return parseFloat(rateTrimmed)
}
