import { defineStore } from 'pinia'
import useSessionStore from './session'

const useNavigationStore = defineStore('navigation', {
    actions: {
        canAccessTo(route: {
            params: {
                idOrga: string | undefined
                idBU: string | undefined
            }
        }) {
            return new Promise((resolve) => {
                const { idOrga, idBU } = route.params
                if (!idOrga) {
                    return resolve(true)
                }

                const organizations = useSessionStore().getOrganizationsSession

                const organisation = organizations.filter(
                    (orga: any) =>
                        parseInt(orga.id, 10) === parseInt(idOrga, 10)
                )
                const hasOrganization = organisation.length === 1
                if (!hasOrganization) {
                    return resolve(false)
                }
                if (!idBU) {
                    return resolve(true)
                }
                const bus = organisation[0].businessUnits
                const hasBus = bus.length > 0
                if (!hasBus) {
                    return resolve(false)
                }
                return resolve(
                    bus.filter(
                        (bu: any) => parseInt(bu.id, 10) === parseInt(idBU, 10)
                    ).length === 1
                )
            })
        }
    }
})

export default useNavigationStore
