import useSessionStore from '~/store/session'
import useOrganizationStore from '~/store/organization'

export default function (context) {
    const onFirstAttemptOrRefresh = process.server

    if (
        onFirstAttemptOrRefresh ||
        !useSessionStore().user ||
        !useOrganizationStore().organization
    ) {
        // TO REMOVE AFTER PINIA MIGRATION ENDED
        const contextForVuex = {
            ...context,
            $store: context.store,
            $i18n: context.i18n,
            $auth: context.$auth || context.auth
        }

        return useSessionStore()
            .loadUser(contextForVuex)
            .then(async () => {
                await useSessionStore().initSession(context, context.route)
            })
            .then(() => {
                if (
                    !useSessionStore().hasAccessRights &&
                    context.route.name !== 'profile' &&
                    context.route.name !== 'login'
                ) {
                    return context.error({
                        statusCode: 403,
                        message: 'no_rights'
                    })
                }
            })
            .catch(() => {
                if (context.route.name !== 'login') {
                    return context.error({
                        statusCode: 500,
                        message: 'errors.oops'
                    })
                }
            })
    }
}
