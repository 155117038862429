import { PassType } from './pass'
import { Connector } from './connector'
import { BusinessUnit, BusinessUnitJSON } from '~/interfaces/businessunit'
import { ZoneCommon } from '~/interfaces/zone'
import { Authorization, AuthorizationDTO } from '~/interfaces/authorization'

export enum chartColors {
    ENERGY = '#3B82F6',
    POWER = '#F97316',
    TENSION = '#22C55E',
    CURRENT = '#A855F7'
}

export interface DateAvailable {
    year: number
    months: number[]
}

export interface MonthAvailableBilledExpenses {
    month: number
    reference: string
    link: string
}
export interface DateAvailableBilledExpenses {
    year: number
    months: MonthAvailableBilledExpenses[]
}
export interface IndexedArray {
    [key: string]: string
}
export interface OptionSelect {
    value: number
    text: string
}

export interface CompteRenduDeRecharge {
    date: string
    energyTransferred: number
    instantCurrent: number | null
    instantPower: number | null
    instantVoltage: number | null
}

export enum RefundStatus {
    ALLOWED = 'ALLOWED',
    BLOCKED = 'BLOCKED',
    BILLED = 'BILLED',
    REFUNDABLE = 'REFUNDABLE',
    REFUNDED = 'REFUNDED',
    BLOCKED_SUSPICIOUS = 'BLOCKED_SUSPICIOUS',
    ALL = 'ALL'
}

export enum InspectionStatus {
    SUSPICIOUS = 'SUSPICIOUS',
    FIXED = 'FIXED'
}

export enum SuspiciousCategories {
    ENERGY_DROP_WITHOUT_RECOVERY = 'ENERGY_DROP_WITHOUT_RECOVERY',
    INVALID_CDR_TIMESTAMP = 'INVALID_CDR_TIMESTAMP'
}

export enum TypeCharge {
    PRIVATE = 'PRIVATE',
    DOMESTIC = 'DOMESTIC',
    PUBLIC = 'PUBLIC'
}

export interface CompteRenduDeRechargeJSON {
    date: string
    energy_transferred: number
    instant_current: number | null
    instant_power: number | null
    instant_voltage: number | null
}

export interface ChargeCommonJSON {
    id: number
    uid: string
    energy: number
    duration: number
    collaborator: {
        id: number
        name: string
    } | null
    business_unit?: BusinessUnitJSON
    vehicle: {
        id: number
        name: string
    } | null
    pass: {
        rfid_uid: string
        id: number | null
        serial?: string
        bu_name?: string
        is_internal: boolean
        type?: PassType | null
    } | null
}

export interface ChargeCommon {
    id: number
    uid: string
    energy: number
    duration: number
    businessUnit?: BusinessUnit
    collaborator: {
        id: number
        name: string
        hasIban?: boolean
    } | null
    vehicle: {
        id: number
        name: string
    } | null
    pass: {
        rfidUid: string
        id: number | null
        serial?: string
        buName?: string
        isInternal: boolean
        type?: PassType | null
    } | null
}

export interface InternalChargeJSON extends ChargeCommonJSON {
    zone: ZoneCommon
    authorization?: AuthorizationDTO
    connector: Connector
    start_date: string
    end_date: string
    date_lost: string | null
    inactivity_duration: number
    average_power: number
    cost?: number | null
    amount?: number | null
    income?: number | null
    refund_status: RefundStatus
    chargebox: {
        id: number
        name: string
    }
    last_cdr: CompteRenduDeRechargeJSON | null
    cdrs: CompteRenduDeRechargeJSON[]
    inspection_status: InspectionStatus | null
    suspicious_categories?: SuspiciousCategories[] | null
}

export interface InternalCharge extends ChargeCommon {
    zone: ZoneCommon
    authorization?: Authorization
    connector: Connector
    startDate: string
    endDate: string
    dateLost: string | null
    cost?: number | null
    amount?: number | null
    income?: number | null
    inactivityDuration: number
    averagePower: number
    refundStatus: RefundStatus
    chargebox: {
        id: number
        name: string
    }
    lastCdr: CompteRenduDeRecharge | null
    cdrs: CompteRenduDeRecharge[]
    inspectionStatus: InspectionStatus | null
    suspiciousCategories?: SuspiciousCategories[] | null
}
export interface ExternalChargeJSON extends ChargeCommonJSON {
    success: boolean
    location_name: string
    address: string | null
    zipcode: string | null
    city: string | null
    country: string | null
    latitude: string | null
    longitude: string | null
    untaxed_price: string
    charge_date: string
    cdr_reception_date: string | null
    invoice_no: number | null
    business_unit_id: number
    organization_id: number
}
export interface ExternalCharge extends ChargeCommon {
    success: boolean
    network: string | null
    locationName: string
    address: string | null
    zipcode: string | null
    city: string | null
    country: string | null
    latitude: number | null
    longitude: number | null
    untaxedPrice: string
    chargeDate: string
    cdrReceptionDate: string | null
    invoiceNo: string | null
    businessUnitId: number
    organizationId: number
}

export interface InternalDomesticChargeJSON extends ChargeCommonJSON {
    zone: ZoneCommon
    authorization?: AuthorizationDTO
    connector: Connector
    start_date: string
    end_date: string
    inactivity_duration: number
    average_power: number
    cost?: string | null
    amount?: number | null
    refund_status: RefundStatus
    chargebox: {
        id: number
        name: string
        identity: string
    }
    evse_id: number
    last_cdr: CompteRenduDeRechargeJSON | null
    inspection_status: InspectionStatus | null
}

export interface ConnectedCableChargeJSON extends InternalChargeJSON {
    at_home: boolean | null
}

export interface InternalDomesticCharge extends ChargeCommon {
    zone: ZoneCommon
    authorization?: Authorization
    connector: Connector
    startDate: string
    endDate: string
    cost?: string | null
    amount?: number | null
    inactivityDuration: number
    averagePower: number
    refundStatus: RefundStatus
    chargebox: {
        id: number
        name: string
        identity: string
    }
    evseId: number
    lastCdr: CompteRenduDeRecharge | null
    inspectionStatus: InspectionStatus | null
}

export interface ConnectedCableCharge extends InternalCharge {
    atHome: boolean | null
}

export enum ChargeExportType {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE'
}

export enum ExportFileType {
    CSV = 'CSV',
    XLSX = 'XLSX'
}

export enum ChargeExportTabs {
    FLEET = 'FLEET',
    SUPERVISED = 'SUPERVISED'
}

export enum DomesticConnectedCableExport {
    DOMESTIC = 'DOMESTIC',
    CONNECTED_CABLE = 'CONNECTED_CABLE',
    REFUNDABLE_DOMESTIC = 'REFUNDABLE_DOMESTIC',
    REFUNDABLE_CONNECTED_CABLE = 'REFUNDABLE_CONNECTED_CABLE'
}

export enum TypeOrigin {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
    DASHBOARD = 'dashboard',
    PRIVATE = 'private',
    DOMESTIC = 'domestic'
}

export enum AggregationType {
    ORGANIZATION = 'ORGANIZATION',
    DRIVER = 'DRIVER',
    VEHICLE = 'VEHICLE',
    PASS = 'PASS',
    TYPE = 'TYPE'
}
