import { User, UserJSON, UserFilter, UserBU, UserRole } from '~/interfaces/user'
import useBusinessUnitStore from './businessunit'
import { RoleIcons, BusinessUnitRoles } from '~/interfaces/roles'
import useSessionStore from './session'

const listUserColors = [
    'red',
    'yellow',
    'orange',
    'green',
    'blue',
    'indigo',
    'purple',
    'pink'
]

const actions = {
    updateUser(payload: { idOrga: number; user: User }) {
        if (payload.user.businessUnits) {
            payload.user.businessUnits.forEach((bu) => {
                bu.roles = []
            })
        } else {
            payload.user.businessUnits = []
        }
        if (payload.user.roles) {
            payload.user.roles.forEach((r: UserRole) => {
                if (r.businessUnit && r.role) {
                    let businessUnit: UserBU | undefined =
                        payload.user.businessUnits.find(
                            (bu: {
                                id: number
                                name: string
                                roles: string[]
                            }) => bu.id === r.businessUnit
                        )
                    if (!businessUnit) {
                        businessUnit = {
                            id: r.businessUnit as number,
                            name: 'not needed',
                            roles: []
                        }
                        payload.user.businessUnits.push(businessUnit)
                    }
                    if (
                        businessUnit &&
                        businessUnit.roles &&
                        !businessUnit.roles.includes(r.role)
                    ) {
                        businessUnit.roles.push(r.role)
                    }
                }
            })
        }
        const user: UserJSON = (this as any).$toSnake(payload.user)
        delete user.color
        delete user.roles
        delete user.business_units
        delete user.is_organization_admin

        const business_unit_roles = payload.user.businessUnits
            .filter(
                (bu: {
                    id: number
                    name: string
                    roles: BusinessUnitRoles[]
                }) => bu.roles.length > 0
            )
            .map(
                (bu: {
                    id: number
                    name: string
                    roles: BusinessUnitRoles[]
                }) => ({
                    business_unit_id: bu.id,
                    roles: bu.roles
                })
            )
        const userJSON = {
            ...user,
            business_unit_roles
        }

        let url = ''
        if (!payload.idOrga) {
            url = `/cloudapi/users/${payload.user.id}`
        } else {
            const { businessUnit } = useBusinessUnitStore()
            url = `/cloudapi/organizations/${payload.idOrga}`
            url += businessUnit?.id
                ? `/business-units/${businessUnit.id}/users`
                : '/users'
        }

        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$put(url, userJSON)
                .then((res: any) => {
                    // If the idOrga is not defined, it means that the user is updating his own profile
                    // So we need to update the locale in the user cookie
                    if (!payload.idOrga) {
                        const userCookie = (
                            this as any
                        ).$auth.$storage.getCookie('user')

                        userCookie.locale = payload.user.locale
                        ;(this as any).$auth.$storage.setUniversal(
                            'user',
                            userCookie
                        )
                    }

                    resolve(res)
                })
                .catch((e: any) => {
                    reject(new Error(e))
                })
        })
    },
    deleteUser(payload: { userId: number; idOrga: number }) {
        const { businessUnit } = useBusinessUnitStore()
        let url = `/cloudapi/organizations/${payload.idOrga}`
        url += businessUnit?.id
            ? `/business-units/${businessUnit.id}/users/${payload.userId}`
            : `/users/${payload.userId}`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$delete(url)
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(new Error(e))
                })
        })
    },
    impersonate(
        vuexContext: any,
        payload: { userId: number; password: string; login: string }
    ) {
        const { userId, password, login } = payload
        const url = `/cloudapi/impersonate`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$post(url, {
                    login,
                    password,
                    impersonated_user_id: userId
                })
                .then(async (res: any) => {
                    await useSessionStore().setUpUser(
                        {
                            ...this,
                            $store: vuexContext
                        },
                        res
                    )
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getUser(vuexContext: any, payload: { userId: number; idOrga: number }) {
        const { businessUnit } = useBusinessUnitStore()
        let url = `/cloudapi/organizations/${payload.idOrga}`
        url += businessUnit?.id
            ? `/business-units/${businessUnit.id}/users/${payload.userId}`
            : `/users/${payload.userId}`
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(url)
                .then((res: any) => {
                    const user = (this as any).$toCamel(res)
                    return vuexContext
                        .dispatch('getUserColor', user.id)
                        .then((result: string) => {
                            user.color = result
                            let buRolesList: {
                                name: BusinessUnitRoles
                                icon: string
                            }[] = []
                            const setRoles = (
                                bu: UserBU,
                                role: BusinessUnitRoles
                            ) => {
                                const icon = RoleIcons[role]
                                return {
                                    businessUnit: bu.id,
                                    role,
                                    icon
                                }
                            }
                            if (user.businessUnits) {
                                user.roles = []
                                user.businessUnits.forEach((bu: UserBU) => {
                                    buRolesList = []
                                    bu.roles.forEach(
                                        (role: BusinessUnitRoles) => {
                                            const icon: string = RoleIcons[role]
                                            buRolesList.push({
                                                name: role,
                                                icon
                                            })
                                            user.roles.push({
                                                businessUnit: bu.id,
                                                role,
                                                icon
                                            })
                                        }
                                    )
                                    bu.rolesIcons = buRolesList
                                })
                            } else if (businessUnit) {
                                const bu: UserBU = {
                                    id: businessUnit.id,
                                    name: businessUnit.name,
                                    roles: [],
                                    rolesIcons: []
                                }
                                user.businessUnits = [bu]
                                user.roles = user.roles.map(
                                    (role: BusinessUnitRoles) => {
                                        const icon = RoleIcons[role]
                                        buRolesList.push({ name: role, icon })
                                        return setRoles(bu, role)
                                    }
                                )
                                bu.rolesIcons = buRolesList
                            }

                            resolve(user)
                        })
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    },
    getUsers(
        vuexContext: any,
        payload: { idOrga: number; filters: UserFilter }
    ) {
        const offset = ((payload.filters.pages.index - 1) *
            payload.filters.pages.perPage) as number
        const limit = payload.filters.pages.perPage as number
        const keyword = payload.filters.searchField || (null as null | string)
        const params = { offset, limit, keyword }
        const { businessUnit } = useBusinessUnitStore()
        let url = `/cloudapi/organizations/${payload.idOrga}`
        url += businessUnit?.id
            ? `/business-units/${businessUnit.id}/users`
            : '/users'
        const setRoles = (bu: UserBU, role: BusinessUnitRoles) => {
            const icon = RoleIcons[role]
            return {
                businessUnit: {
                    id: bu.id,
                    name: bu.name
                },
                role,
                icon
            }
        }
        return new Promise((resolve, reject) => {
            ;(this as any).$axios
                .$get(url, { params })
                .then((res: any) => {
                    const result = (this as any).$toCamel(res)
                    result.users.forEach((user: User) => {
                        vuexContext
                            .dispatch('getUserColor', user.id)
                            .then((color: string) => {
                                user.color = color
                                if (user.businessUnits) {
                                    user.roles = []
                                    user.businessUnits.forEach((bu) => {
                                        bu.roles.forEach((role) => {
                                            user.roles!.push(setRoles(bu, role))
                                        })
                                    })
                                } else if (user.roles && businessUnit) {
                                    const bu = {
                                        id: businessUnit.id,
                                        name: '',
                                        roles: []
                                    }
                                    const roles = [] as UserRole[]
                                    user.businessUnits = [bu]
                                    const listRoles =
                                        user.roles as unknown as BusinessUnitRoles[]
                                    listRoles.forEach(
                                        (userRole: BusinessUnitRoles) => {
                                            roles.push(setRoles(bu, userRole))
                                        }
                                    )
                                    user.roles = roles
                                } else {
                                    user.roles = []
                                }
                            })
                    })
                    resolve(result)
                })
                .catch((e: any) => {
                    reject(new Error(e))
                })
        })
    },
    getUserColor(_: any, userId: number) {
        return listUserColors[userId ? userId % listUserColors.length : 0]
    }
}

export default {
    namespaced: true as const,
    actions
}
