import useSessionStore from '~/store/session'

export default function (context) {
    const hasRefreshToken = context.$auth?.strategy?.refreshToken
        ?.status()
        ?.valid()
    if (useSessionStore().user && hasRefreshToken) {
        if (!useSessionStore().isSuperAdmin) {
            const { homepage } = useSessionStore()
            if (context.route.path !== homepage) {
                return context.redirect(homepage)
            }
        }
    } else {
        return useSessionStore()
            .logout(context)
            .finally(() => context.redirect('/login#session-redirect'))
    }
}
