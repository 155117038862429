import useBusinessUnitStore from '@/store/businessunit'

export default async function (context) {
    const hasOnboardingState = useBusinessUnitStore().hasOnboardingState()

    if (!hasOnboardingState) {
        await useBusinessUnitStore().fetchOnboardingState(
            context,
            context.route.params
        )
    }
}
