import { createLocalVue, shallowMount } from '@vue/test-utils'
import MenuAdminSection from '@/components/UI/menu/MenuAdminSection.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'
import useSessionStore, { SessionStore } from '~/store/session'
import user from '~/data/user'

const localVue = createLocalVue()

describe('MenuAdminSection.vue', () => {
    let wrapper: any
    let sessionStore: SessionStore

    beforeEach(() => {
        sessionStore = useSessionStore()
        sessionStore.user = user

        wrapper = shallowMount(MenuAdminSection, {
            ...basicMountWrapper,
            mocks: {
                ...basicMountWrapper.mocks
            },
            localVue
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="menu-admin-section"]')
        expect(component.exists()).toBeTruthy()
    })

    it("should be displayed if it's super admin", () => {
        sessionStore.user = {
            ...user,
            rights: {
                ...user.rights,
                superAdmin: true
            }
        }
        expect(wrapper.vm.adminSections.authorized).toBeTruthy()
        sessionStore.user = {
            ...user,
            rights: {
                ...user.rights,
                superAdmin: false
            }
        }
        expect(wrapper.vm.adminSections.authorized).toBeFalsy()
    })
    it('switchMenu', () => {
        expect(wrapper.emitted().selected).toEqual(undefined)
        wrapper.vm.switchMenu(1)
        expect(wrapper.emitted().selected[0]).toEqual([1])
    })

    it('closeMobileMenu', () => {
        expect(wrapper.emitted().closeMobileMenu).toEqual(undefined)
        wrapper.vm.closeMobileMenu()
        expect(wrapper.emitted().closeMobileMenu[0]).toEqual([])
    })
})
