import { ref } from 'vue'
import { defineStore } from 'pinia'
import { PaymentInfo, PaymentType, RouteInfo } from '~/interfaces/payment'
import { BillingAccount } from '~/interfaces/businessunit'

const convertToPayload = (paymentInfo: PaymentInfo) => {
    const { paymentSelected, paymentId } = paymentInfo.payment
    switch (paymentSelected) {
        case PaymentType.CREDIT:
            return {
                type: 'STRIPE_CB',
                stripe_payment_mean_id: paymentId
            }
        case PaymentType.DEBIT:
            return {
                type: 'DEBIT',
                gocardless_mandate_id: paymentId
            }
        case PaymentType.BANK_TRANSFER:
            return {
                type: 'BANK_TRANSFER'
            }
        default:
            return null
    }
}

const usePaymentStore = defineStore('payment', () => {
    const mandateId = ref(null as string | null)
    const goCardlessUrl = ref(null as string | null)
    const billingAccount = ref({
        email: '',
        lang: 'FR',
        type: 'COMPANY',
        billingAddress: {
            firstName: '',
            name: '',
            company: '',
            addressLine1: '',
            addressLine2: '',
            postalCode: '',
            city: '',
            countryCode: 'FR',
            countryName: 'France',
            phone: '',
            vatNumber: ''
        }
    } as BillingAccount)

    const getSetupIntent = (context: any, infoPayment: RouteInfo) => {
        const { idOrga, idBU } = infoPayment
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/payment-mean/stripe/setup-intent`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url)
                .then((res: any) => {
                    const clientSecret = res.client_secret
                    if (!clientSecret) {
                        reject(new Error('Mandate id is missing'))
                    }
                    resolve(clientSecret)
                })
                .catch((e: any) => {
                    reject(e.response)
                })
        })
    }

    const sendPayment = (context: any, infoPayment: PaymentInfo) => {
        const { idOrga, idBU } = infoPayment.routeInfo
        const payload = convertToPayload(infoPayment)
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/payment-mean`
        return new Promise((resolve, reject) => {
            context.$axios
                .$post(url, payload)
                .then((res: any) => resolve(res))
                .catch((e: any) => reject(e.response))
        })
    }

    const completeCredit = (payload: {
        stripe: any
        clientSecret: string
        card: any
    }) =>
        new Promise((resolve, reject) => {
            payload.stripe
                .confirmCardSetup(
                    payload.clientSecret,
                    {
                        payment_method: {
                            card: payload.card
                        }
                    },
                    { handleAction: false }
                )
                .then((result: any) => {
                    if (
                        !result ||
                        result.setupIntent?.payment_method === 'None'
                    ) {
                        reject(new Error('Error, payment not processed'))
                    }
                    if (result.error) {
                        const error = result.error.message || result.error
                        reject(error)
                    }
                    const paymentId = result.setupIntent.payment_method
                    resolve(paymentId)
                })
                .catch((e: any) => {
                    const error =
                        e.response?.message || 'Error, something went wrong'
                    reject(new Error(error))
                })
        })

    const deletePayment = (
        context: any,
        payload: { idOrga: number; idBU: number }
    ) => {
        const { idOrga, idBU } = payload
        return context.$axios
            .delete(
                `/cloudapi/organizations/${idOrga}/business-units/${idBU}/payment-mean/default`
            )
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    return {
        mandateId,
        goCardlessUrl,
        billingAccount,
        getSetupIntent,
        sendPayment,
        completeCredit,
        deletePayment
    }
})

export default usePaymentStore
