
import Inaccessible from '~/components/UI/icons/errors/Inaccessible.vue'
import NotFound from '~/components/UI/icons/errors/NotFound.vue'
import LicenseNotFound from '@/components/cloud/organization/LicenseNotFound.vue'
import useOrganizationStore from '~/store/organization'

export default {
    components: { Inaccessible, NotFound, LicenseNotFound },
    layout: 'error',
    props: {
        error: {
            type: Object,
            default() {
                return {
                    statusCode: 500,
                    message: 'Have You Tried Turning It Off and On Again? :D'
                }
            }
        }
    },
    computed: {
        email() {
            const email =
                useOrganizationStore().organization?.mainContact?.email
            return email ? ` : ${email}` : ' '
        }
    }
}
