export type ReducedPeriodDTO = {
    price: number
    start_time: string
    end_time: string
}

export type ReducedPeriod = {
    price: number
    startTime: string
    endTime: string
}

export enum DeliveryPointPeriodType {
    CLASSIC = 'CLASSIC',
    REDUCED = 'REDUCED',
    CONTROLLED_ENERGY = 'CONTROLLED_ENERGY'
}

export interface TimeRange {
    startTime: string
    endTime: string
    price: string | number | null
}

export interface Period {
    name: string
    startDate: string
    endDate: string
    timeRanges: TimeRange[]
}

export interface DeliveryPointTariff {
    id?: number
    billEnergy: boolean
    billedEnergyMargin: number | null
    businessUnitId?: number
    deliveryPointId?: number
    dateCreated?: string
    periods: Period[]
    type: DeliveryPointPeriodType
}

export type DeliveryPointDTO = {
    id?: number
    identifier: string
    name: string
    comment: string
    collaborator?: {
        id: number
        name: string
        has_iban: boolean
    }
    tariff: DeliveryPointTariff
    zones_count?: number
    max_power: number | null
}

export type DeliveryPoint = {
    id?: number
    identifier: string
    name: string
    comment: string
    collaborator?: {
        id: number
        name: string
        hasIban: boolean
    }
    tariff: DeliveryPointTariff
    zonesCount?: number
    maxPower: number | null
    dateDeleted?: string | null
}
