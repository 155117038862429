import { OrganizationRoles } from '~/interfaces/roles'

export default {
    id: 1,
    activated: true,
    name: 'Test',
    address: '999 rue test',
    postalCode: '67000',
    city: 'Strasbourg',
    country: 'France',
    mainContact: {
        id: 1,
        firstName: 'test',
        lastName: 'user',
        email: 'test@test.fr',
        phone: null
    },
    access: [],
    buCount: 1,
    role: OrganizationRoles.ADMIN,
    businessUnits: [
        {
            id: 1,
            name: 'businessUnit1',
            activated: true,
            roles: [],
            notifyMainContact: false,
            administrator: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: null
            },
            paymentMethodsAvailable: []
        },
        {
            id: 2,
            name: 'businessUnit2',
            activated: true,
            roles: [],
            notifyMainContact: false,
            administrator: {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: null
            },
            paymentMethodsAvailable: []
        }
    ],
    billOtherOrganizationBu: true
}
