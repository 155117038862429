import { defineStore } from 'pinia'
import { ref } from 'vue'
import {
    DeliveryPoint,
    DeliveryPointPeriodType,
    DeliveryPointTariff,
    Period
} from '~/interfaces/deliveryPoint'
import { FilterList } from '~/interfaces/filters'
import convertToPrice from '~/utils/other'

const useDeliveryPointsStore = defineStore('deliveryPoints', () => {
    const currentTariffType = ref<DeliveryPointPeriodType>(
        DeliveryPointPeriodType.CLASSIC
    )
    const tariffClassicEdited = ref<DeliveryPointTariff | null>(null)
    const tariffReducedEdited = ref<DeliveryPointTariff | null>(null)
    const tariffControlledEdited = ref<DeliveryPointTariff | null>(null)

    const defaultClassicPeriod = {
        name: 'default',
        startDate: '01-01',
        endDate: '12-31',
        timeRanges: [
            {
                startTime: '00:00',
                endTime: '00:00',
                price: null
            }
        ]
    } as Period

    const defaultReducedPeriod = {
        name: 'default',
        startDate: '01-01',
        endDate: '12-31',
        timeRanges: [
            {
                startTime: '00:00',
                endTime: '12:00',
                price: null
            },
            {
                startTime: '',
                endTime: '',
                price: null
            }
        ]
    } as Period

    const defaultControlledPeriod = {
        name: 'Period 1',
        startDate: '01-01',
        endDate: '12-31',
        timeRanges: [
            {
                startTime: '00:00',
                endTime: '00:00',
                price: null
            }
        ]
    } as Period

    const defaultTariff = {
        id: undefined,
        billEnergy: false,
        businessUnitId: undefined,
        deliveryPointId: undefined,
        billedEnergyMargin: 0,
        periods: [defaultClassicPeriod],
        type: DeliveryPointPeriodType.CLASSIC,
        dateCreated: undefined
    } as DeliveryPointTariff

    const convertRate = (
        deliveryPoint: DeliveryPoint,
        divided: boolean = true
    ): DeliveryPoint => {
        const TO_EUROS = 100_000
        const TO_KW = 1_000

        const periods = deliveryPoint.tariff.periods.map((period) => ({
            ...period,
            timeRanges: period.timeRanges.map((timeRange) => ({
                ...timeRange,
                price: divided
                    ? (convertToPrice(timeRange.price) / TO_EUROS).toString()
                    : convertToPrice(timeRange.price) * TO_EUROS
            }))
        }))

        let { maxPower } = deliveryPoint
        if (maxPower) {
            maxPower = divided ? maxPower / TO_KW : maxPower * TO_KW
        }
        return {
            ...deliveryPoint,
            tariff: {
                ...deliveryPoint.tariff,
                periods
            },
            maxPower
        }
    }

    const fetchDeliveryPoints = (
        context: any,
        payload: {
            idBU: number
            hasZones: boolean | null
            hasOwner: boolean | null
            maxTimeRanges?: number | null
            tariffTypes?: DeliveryPointPeriodType[] | null
            filters: FilterList
            archive: boolean | null
        }
    ): Promise<{ count: number; deliveryPoints: DeliveryPoint[] }> => {
        const {
            idBU,
            hasZones,
            hasOwner,
            filters,
            archive,
            maxTimeRanges,
            tariffTypes
        } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const params = {
            has_zones: hasZones,
            has_owner: hasOwner,
            max_time_ranges: maxTimeRanges,
            tariff_types: tariffTypes,
            offset,
            limit,
            keyword
        }
        const url = `/supervisionapi/business-units/${idBU}/delivery-points${
            archive ? '' : '?deleted=false'
        }`
        return context.$axios
            .get(url, { params })
            .then((response: any) => {
                const resDTO = response.data.items
                const count = parseInt(response.headers['x-total-count'], 10)
                const deliveryPoints = context.$toCamel(
                    resDTO
                ) as DeliveryPoint[]

                return {
                    count,
                    deliveryPoints: deliveryPoints.map((deliveryPoint) =>
                        convertRate(deliveryPoint)
                    )
                }
            })
            .catch((e: any) => {
                throw e
            })
    }

    const fetchDeliveryPoint = (
        context: any,
        payload: {
            idBU: number
            idDeliveryPoint: number
        }
    ) => {
        const { idBU, idDeliveryPoint } = payload
        const url = `/supervisionapi/business-units/${idBU}/delivery-points/${idDeliveryPoint}`
        return context.$axios
            .$get(url)
            .then((res: any) => {
                const deliveryPoint = context.$toCamel(res)
                return convertRate(deliveryPoint)
            })
            .catch((e: any) => {
                throw e
            })
    }

    const saveDeliveryPoint = (
        context: any,
        payload: { deliveryPoint: DeliveryPoint; idBU: number }
    ) => {
        const { deliveryPoint, idBU } = payload
        let url = `/supervisionapi/business-units/${idBU}/delivery-points`
        let method = '$post'
        if (deliveryPoint.id) {
            url += `/${deliveryPoint.id}`
            method = '$put'
        }
        const body = {
            ...context.$toSnake(convertRate(deliveryPoint, false)),
            id: undefined
        }

        return context.$axios[method](url, body)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    const disableDeliveryPoint = (
        context: any,
        payload: { idDeliveryPoint: number; idBU: number }
    ) => {
        const { idDeliveryPoint, idBU } = payload
        const url = `/supervisionapi/business-units/${idBU}/delivery-points/${idDeliveryPoint}`
        return context.$axios
            .$delete(url)
            .then((res: any) => res)
            .catch((e: any) => {
                throw e
            })
    }

    const resetState = () => {
        currentTariffType.value = DeliveryPointPeriodType.CLASSIC
        tariffClassicEdited.value = null
        tariffReducedEdited.value = null
        tariffControlledEdited.value = null
    }

    return {
        currentTariffType,
        tariffClassicEdited,
        tariffReducedEdited,
        tariffControlledEdited,
        defaultClassicPeriod,
        defaultReducedPeriod,
        defaultControlledPeriod,
        defaultTariff,
        fetchDeliveryPoints,
        fetchDeliveryPoint,
        saveDeliveryPoint,
        disableDeliveryPoint,
        resetState
    }
})

type DeliveryPointStore = Omit<
    ReturnType<typeof useDeliveryPointsStore>,
    keyof ReturnType<typeof defineStore>
>

export default useDeliveryPointsStore

export type { DeliveryPointStore }
