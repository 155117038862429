import { organizationWithBu } from '~/data/cloud/organization/organizations'
import { OrganizationRight, UserToken } from '~/interfaces/user'

const user = {
    id: 1,
    firstName: 'Pierre',
    lastName: 'Dupont',
    fullName: 'Pierre Dupont',
    email: 'pierre.dupont@chargemap.fr',
    businessUnits: [],
    isSubscribedToNewsletter: false,
    isOrganizationAdmin: false,
    locale: 'FR',
    rights: {
        superAdmin: false,
        organizations: [organizationWithBu as OrganizationRight]
    }
} as UserToken

export default user
