import { Plan } from './flaggy'
import { OrganizationRoles } from './roles'
import { BusinessUnit } from '~/interfaces/businessunit'

export enum CancelContractType {
    IMMEDIATE = 'IMMEDIATE',
    BIRTHDAY = 'BIRTHDAY'
}

export interface Contact {
    id: number
    firstName: string
    lastName: string
    email: string
    phone: string | null
}
export interface ContactJSON {
    id?: number
    first_name: string
    last_name: string
    email: string
    phone: string | null
}
export interface UserOrganization {
    id: number
    activated: boolean
    name: string
    address: string
    postalCode: string
    city: string
    country: string
    mainContact: Contact
    access: string[]
    buCount: number
    role: OrganizationRoles
    businessUnits: BusinessUnit[]
    billOtherOrganizationBu: boolean
}

export enum PaymentMethodsAvailable {
    DEBIT = 'DEBIT',
    STRIPE_CB = 'STRIPE_CB',
    BANK_TRANSFER = 'BANK_TRANSFER'
}

export interface AccountManager {
    name: string
    pipedriveId: number
}
export interface Organization {
    id: number
    activated: boolean
    name: string
    notifyCollaborators: boolean
    enableCallForFunds: boolean
    address: string
    postalCode: string
    city: string
    country: string
    mainContact: Contact
    access: string[]
    buCount: number
    paymentTerm: number
    paymentMethodsAvailable?: string[]
    billOtherOrganizationBu: boolean
    pipedriveId: number | null
    accountManager: AccountManager | null
    plan?: Plan
}

export interface AccountManagerJSON {
    name: string
    pipedrive_id: number
}
export interface OrganizationJSON {
    id?: number
    activated: boolean
    name: string
    notify_collaborators: boolean
    enable_call_for_funds: boolean
    main_contact: ContactJSON
    access: string[]
    bu_count?: number
    payment_term: number
    payment_methods_available?: string[]
    bill_other_organization_bu: boolean
    pipedrive_id: number | null
    account_manager: AccountManagerJSON | null
}

export interface OrganizationDTO {
    count: number
    organizations: Organization[]
    next: string | null
    previous: string | null
}

export enum Functionality {
    FLEET = 'FLEET',
    SUPERVISION = 'SUPERVISION',
    OPERATOR = 'OPERATOR',
    BLOCKING_PASS = 'BLOCKING_PASS',
    BETA = 'BETA'
}

export enum Pipeline {
    SUPERVISION = 'SUPERVISION',
    ROAMING = 'ROAMING'
}

export interface OrganizationPipedrive {
    id: number
    personId: number
    organizationId: number
    organizationName: string
    stage: number
    title: string
    addTime: string
}

export interface OrganizationPipedriveJSON {
    id: number
    person_id: number
    organization_id: number
    organization_name: string
    stage: number
    title: string
    add_time: string
}

export interface OrganizationPipedriveDetail {
    name: string
    address: string
    postalCode: string
    city: string
    country: string
    mainContact: {
        id: number
        email: string
        firstName: string
        lastName: string
        phone: string
        locale: string
    }
    pipedriveId: number
    accountManager: {
        pipedriveId: number
        name: string
    }
}
