
import Vue from 'vue'
import IconConnectorStatusAVAILABLE from '@/components/UI/icons/connectors/status/IconConnectorStatusAVAILABLE.vue'
import IconConnectorStatusCHARGING from '@/components/UI/icons/connectors/status/IconConnectorStatusCHARGING.vue'
import IconConnectorStatusFAULTED from '@/components/UI/icons/connectors/status/IconConnectorStatusFAULTED.vue'
import IconConnectorStatusFINISHING from '@/components/UI/icons/connectors/status/IconConnectorStatusFINISHING.vue'
import IconConnectorStatusPLUGGED from '@/components/UI/icons/connectors/status/IconConnectorStatusPLUGGED.vue'
import IconConnectorStatusPREPARING from '@/components/UI/icons/connectors/status/IconConnectorStatusPREPARING.vue'
import IconConnectorStatusRESERVED from '@/components/UI/icons/connectors/status/IconConnectorStatusRESERVED.vue'

import IconConnectorStatusSUSPENDED_EV from '@/components/UI/icons/connectors/status/IconConnectorStatusSUSPENDED_EV.vue'

import IconConnectorStatusSUSPENDED_EVSE from '@/components/UI/icons/connectors/status/IconConnectorStatusSUSPENDED_EVSE.vue'
import IconConnectorStatusUNAVAILABLE from '@/components/UI/icons/connectors/status/IconConnectorStatusUNAVAILABLE.vue'
import IconConnectorStatusUNKNOWN from '@/components/UI/icons/connectors/status/IconConnectorStatusUNKNOWN.vue'
import { evseStatuses } from '~/interfaces/chargebox'

export default Vue.extend({
    components: {
        IconConnectorStatusAVAILABLE,
        IconConnectorStatusCHARGING,
        IconConnectorStatusFAULTED,
        IconConnectorStatusFINISHING,
        IconConnectorStatusPLUGGED,
        IconConnectorStatusPREPARING,
        IconConnectorStatusRESERVED,
        IconConnectorStatusSUSPENDED_EV,
        IconConnectorStatusSUSPENDED_EVSE,
        IconConnectorStatusUNAVAILABLE,
        IconConnectorStatusUNKNOWN
    },
    props: {
        status: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    },
    computed: {
        statusIcon(): string {
            if (!(this.status in evseStatuses)) {
                return evseStatuses.UNKNOWN
            }
            return this.status
        },
        colorIcon(): string {
            if (this.color === 'black') {
                return 'gray'
            }
            return this.color
        }
    }
})
