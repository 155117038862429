import useSessionStore from '~/store/session'

export default function ({ error }) {
    if (useSessionStore().user && !useSessionStore().isFleetManager) {
        error({
            statusCode: 401,
            message: 'errors.unauthorized'
        })
    }
}
