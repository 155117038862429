import { AxiosError } from 'axios'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import useBusinessUnitStore from './businessunit'
import useSessionStore from './session'
import { OrganizationRight } from '~/interfaces/user'
import {
    Organization,
    UserOrganization,
    OrganizationJSON,
    Functionality,
    Pipeline,
    OrganizationPipedrive,
    OrganizationPipedriveJSON,
    OrganizationDTO
} from '~/interfaces/organization'
import { BusinessUnit, BusinessUnitDTO } from '~/interfaces/businessunit'
import { TokenAPI, TokenAPIJSON } from '~/interfaces/tokenAPI'

import { OrganizationRoles } from '~/interfaces/roles'
import { OrganizationFilter } from '~/interfaces/filters'

const useOrganizationStore = defineStore('organization', () => {
    const organization = ref(null as Organization | UserOrganization | null)
    const keyword = ref('' as string)

    const hasBlockingPass = () =>
        !!organization.value?.access?.includes(Functionality.BLOCKING_PASS)

    const hasBetaOrderFlowAccess = () =>
        !!organization.value?.access?.includes(Functionality.BETA)

    const getOrganization = (
        context: any,
        id: number | undefined
    ): Promise<Organization> => {
        if (id === undefined) {
            return Promise.reject(new Error('No organization'))
        }
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(`/cloudapi/organizations/${id}`)
                .then((res: OrganizationJSON) => resolve(context.$toCamel(res)))
                .catch((e: AxiosError) => reject(e))
        })
    }

    const setOrganization = (
        newOrganization: Organization | UserOrganization | null
    ) => {
        organization.value = newOrganization
        useSessionStore().updateOrganizationRole()
        return Promise.resolve()
    }

    const constructOrganization = (
        context: any,
        payload: { organizationId: number; idBU?: number | null }
    ) => {
        const idOrga = payload.organizationId || organization.value?.id || 0
        return new Promise((resolve, reject) => {
            if (!idOrga) {
                return resolve('No organization set')
            }
            let newOrganization: OrganizationRight
            getOrganization(context, idOrga)
                .then((res: UserOrganization | Organization) => {
                    if (!res) {
                        throw new Error('Organization not found')
                    }
                    newOrganization = res as OrganizationRight
                    const filters = {
                        searchField: '',
                        hasAllBusinessUnit: false,
                        pages: {
                            index: 1,
                            perPage: 100,
                            totalItems: 0,
                            disabled: false
                        }
                    }
                    return useBusinessUnitStore().fetchBusinessUnits(context, {
                        idOrga,
                        filters
                    })
                })
                .then((res: BusinessUnitDTO) => {
                    newOrganization.businessUnits = res.businessUnits
                    newOrganization.role = OrganizationRoles.ADMIN
                    useSessionStore().setOrganizations([newOrganization])
                    setOrganization(newOrganization)
                    if (payload.idBU) {
                        const { idBU } = payload
                        const businessUnit = newOrganization.businessUnits.find(
                            (bu: BusinessUnit) => +bu.id === +idBU
                        )
                        return useBusinessUnitStore()
                            .setBusinessUnit(businessUnit || null)
                            .then(() => {
                                resolve(res)
                            })
                    }
                    resolve(res)
                })
                .catch((e: AxiosError) => reject(e))
        })
    }

    const switchOrganization = async (
        context: any,
        payload: { organizationId: number; idBU?: number | null }
    ) => {
        if (useSessionStore().isSuperAdmin) {
            await constructOrganization(context, payload)
            return
        }

        let newOrganization: UserOrganization | Organization | null = null

        const listOrgaFromUserObject =
            useSessionStore().user?.rights?.organizations || []
        const organizationFromUserObject = listOrgaFromUserObject?.find(
            (orga: any) => orga.id === payload.organizationId
        )

        const firstOrgaFromUserObject = listOrgaFromUserObject?.[0]

        const role =
            organizationFromUserObject?.role ||
            firstOrgaFromUserObject?.role ||
            ''

        if (role === 'ADMIN') {
            await getOrganization(
                context,
                organizationFromUserObject?.id || listOrgaFromUserObject[0]?.id
            )
                .then((res: UserOrganization | Organization) => {
                    if (!res) {
                        throw new Error('Organization not found')
                    }
                    newOrganization = res
                })
                .catch(() => {})
        }

        if (newOrganization) {
            ;(newOrganization as UserOrganization).businessUnits =
                organizationFromUserObject?.businessUnits ||
                listOrgaFromUserObject[0]?.businessUnits
            ;(newOrganization as UserOrganization).role =
                organizationFromUserObject?.role ||
                listOrgaFromUserObject[0]?.role
            setOrganization(newOrganization)
        } else {
            setOrganization(
                listOrgaFromUserObject
                    ? organizationFromUserObject || firstOrgaFromUserObject
                    : null
            )
        }

        if (payload.idBU || role !== 'ADMIN')
            return useBusinessUnitStore().initBusinessUnit(
                context,
                payload.idBU || null
            )
    }

    const fetchOrganizations = (
        context: any,
        filters: OrganizationFilter
    ): Promise<OrganizationDTO> => {
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const isActivated = !filters.hasAllOrganization || null
        const searchField = filters.searchField || (null as null | string)
        const params = {
            offset,
            limit,
            activated: isActivated,
            keyword: searchField
        }
        return new Promise((resolve, reject) => {
            context.$axios
                .$get('/cloudapi/organizations', { params })
                .then((res: OrganizationJSON) => resolve(context.$toCamel(res)))
                .catch((e: AxiosError) => reject(e))
        })
    }

    const createOrganization = (
        context: any,
        params: {
            organization: Organization | null
            shouldSetOrganization: boolean
        } = {
            organization: null,
            shouldSetOrganization: true
        }
    ): Promise<Organization> =>
        new Promise((resolve, reject) => {
            const { organization: newOrganization } = params

            const organizationFormatted: OrganizationJSON =
                context.$toSnake(newOrganization)
            delete organizationFormatted.id
            delete organizationFormatted.bu_count
            if (organizationFormatted.main_contact.phone == null) {
                organizationFormatted.main_contact.phone = ''
            }
            if (organizationFormatted.main_contact.id === 0) {
                delete organizationFormatted.main_contact.id
            }
            context.$axios
                .$post('/cloudapi/organizations', organizationFormatted)
                .then((res: OrganizationJSON) =>
                    context.$auth
                        .refreshTokens()
                        .then(() => resolve(context.$toCamel(res)))
                        .catch((e: AxiosError) => reject(e))
                )
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })

    const updateOrganization = (
        context: any,
        params: {
            organization: Organization | null
            shouldSetOrganization?: boolean
        } = {
            organization: null,
            shouldSetOrganization: true
        }
    ): Promise<Organization> =>
        new Promise((resolve, reject) => {
            const { organization: newOrganization, shouldSetOrganization } =
                params
            if (!newOrganization) return

            const organizationFormatted: OrganizationJSON =
                context.$toSnake(newOrganization)
            delete organizationFormatted.id
            delete organizationFormatted.bu_count
            if (organizationFormatted.main_contact.phone == null) {
                organizationFormatted.main_contact.phone = ''
            }
            if (organizationFormatted.main_contact.id === 0) {
                delete organizationFormatted.main_contact.id
            }
            context.$axios
                .$patch(
                    `/cloudapi/organizations/${newOrganization.id}`,
                    organizationFormatted
                )
                .then((res: OrganizationJSON) => {
                    const orga = context.$toCamel(res)
                    if (shouldSetOrganization) {
                        setOrganization(orga)
                    }
                    resolve(orga)
                })
                .catch((e: AxiosError) => {
                    reject(e)
                })
        })

    const fetchOrganizationsFromPipedrive = (
        context: any,
        pipeline: Pipeline
    ): Promise<OrganizationPipedrive[]> =>
        new Promise((resolve, reject) => {
            context.$axios
                .$get(`/cloudapi/pipedrive/pipelines/${pipeline}/deals`)
                .then((res: OrganizationPipedriveJSON[]) =>
                    resolve(context.$toCamel(res))
                )
                .catch((e: AxiosError) => reject(e))
        })

    const fetchOrganizationFromPipedrive = (
        context: any,
        payload: { organizationId: number; personId: number }
    ): Promise<OrganizationPipedrive> => {
        const { organizationId, personId } = payload
        const params = {
            person_id: personId
        }
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(`/cloudapi/pipedrive/organizations/${organizationId}`, {
                    params
                })
                .then((res: OrganizationPipedriveJSON) =>
                    resolve(context.$toCamel(res))
                )
                .catch((e: AxiosError) => reject(e))
        })
    }

    const fetchTokenAPIS = (
        context: any,
        payload: { idOrga: number; idBU?: number }
    ): Promise<TokenAPI[]> => {
        const { idOrga, idBU } = payload
        return new Promise((resolve, reject) => {
            let url = `/fleetdataapi/organizations/${idOrga}/api-tokens`
            if (idBU) {
                url = `/fleetdataapi/organizations/${idOrga}/business-units/${idBU}/api-tokens`
            }
            context.$axios
                .$get(url)
                .then((res: TokenAPIJSON[]) => resolve(context.$toCamel(res)))
                .catch((e: AxiosError) => reject(e))
        })
    }

    const createTokenAPI = (
        context: any,
        payload: {
            idOrga: number
            idBU?: number
            businessUnitIds?: number[]
            comment: string
        }
    ): Promise<TokenAPI> => {
        const { idOrga, idBU, businessUnitIds, comment } = payload
        return new Promise((resolve, reject) => {
            let url = `/fleetdataapi/organizations/${idOrga}/api-tokens`
            if (idBU) {
                url = `/fleetdataapi/organizations/${idOrga}/business-units/${idBU}/api-tokens`
            }

            const params = {
                business_unit_ids: businessUnitIds || undefined,
                comment
            }

            context.$axios
                .$post(url, params)
                .then((res: TokenAPIJSON) => resolve(context.$toCamel(res)))
                .catch((e: AxiosError) => reject(e))
        })
    }

    const revokeTokenAPI = (
        context: any,
        payload: { idOrga: number; idBU?: number; tokenId: number }
    ): Promise<TokenAPI> => {
        const { idOrga, idBU, tokenId } = payload
        return new Promise((resolve, reject) => {
            let url = `/fleetdataapi/organizations/${idOrga}/api-tokens/${tokenId}`
            if (idBU) {
                url = `/fleetdataapi/organizations/${idOrga}/business-units/${idBU}/api-tokens/${tokenId}`
            }

            context.$axios
                .$delete(url)
                .then((res: TokenAPIJSON) => resolve(context.$toCamel(res)))
                .catch((e: any) => reject(e))
        })
    }

    return {
        organization,
        keyword,
        hasBlockingPass,
        hasBetaOrderFlowAccess,
        constructOrganization,
        switchOrganization,
        setOrganization,
        fetchOrganizations,
        createOrganization,
        getOrganization,
        updateOrganization,
        fetchOrganizationsFromPipedrive,
        fetchOrganizationFromPipedrive,
        fetchTokenAPIS,
        createTokenAPI,
        revokeTokenAPI
    }
})

type OrganizationStore = Omit<
    ReturnType<typeof useOrganizationStore>,
    keyof ReturnType<typeof defineStore>
>

export default useOrganizationStore

export type { OrganizationStore }
