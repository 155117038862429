import { createLocalVue, shallowMount } from '@vue/test-utils'
import MenuUserSection from '@/components/UI/menu/MenuUserSection.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'
import useBusinessUnitStore, { BusinessUnitStore } from '~/store/businessunit'
import useOrganizationStore, { OrganizationStore } from '~/store/organization'
import organizations from '~/data/cloud/organization/organizationsAsType'
import useSessionStore, { SessionStore } from '~/store/session'
import user from '~/data/user'
import { BusinessUnitRoles, OrganizationRoles } from '~/interfaces/roles'

const localVue = createLocalVue()

const filledBU = {
    id: 1,
    name: 'BU test',
    activated: true,
    roles: [],
    notifyMainContact: true,
    administrator: {
        id: 1,
        email: 'jean@jacques.fr',
        firstName: 'Jean',
        lastName: 'Jacques',
        phone: '0123456789'
    },
    paymentMethodsAvailable: []
}

const withoutRight = {}

const withSuperAdminProfile = {
    isSuperAdmin: true
}
const withAdminProfile = {
    isAdmin: true
}
const withBUAdminProfile = {
    isBUAdmin: true
}
const withFleetProfile = {
    isFleetManager: true
}
const withSupervisionProfile = {
    isSupervisionManager: true
}

const setRights = (rights: any) => {
    useSessionStore().organizationRole = OrganizationRoles.MEMBER
    useSessionStore().buRoles = []
    useSessionStore().user = user
    switch (rights) {
        case withSuperAdminProfile:
            useSessionStore().user = {
                ...user,
                rights: {
                    ...user.rights,
                    superAdmin: true
                }
            }
            break
        case withAdminProfile:
            useSessionStore().organizationRole = OrganizationRoles.ADMIN
            break
        case withBUAdminProfile:
            useSessionStore().buRoles = [BusinessUnitRoles.BU_ADMIN]
            break
        case withFleetProfile:
            useSessionStore().buRoles = [BusinessUnitRoles.FLEET_MANAGER]
            break
        case withSupervisionProfile:
            useSessionStore().buRoles = [BusinessUnitRoles.SUPERVISOR]
            break
        default:
            break
    }
}

describe('MenuUserSection.vue', () => {
    let wrapper: any
    let businessUnitStore: BusinessUnitStore
    let organizationStore: OrganizationStore
    let sessionStore: SessionStore

    beforeEach(() => {
        businessUnitStore = useBusinessUnitStore()
        businessUnitStore.hasFilled3OnboardingState = jest
            .fn()
            .mockReturnValue(true)

        organizationStore = useOrganizationStore()
        organizationStore.organization = organizations

        sessionStore = useSessionStore()
        sessionStore.user = user

        wrapper = shallowMount(MenuUserSection, {
            ...basicMountWrapper,
            localVue,
            mocks: {
                ...basicMountWrapper.mocks
            }
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="menu-user-section"]')
        expect(component.exists()).toBeTruthy()
    })
    it('switchMenu', () => {
        expect(wrapper.emitted().selected).toEqual(undefined)
        wrapper.vm.switchMenu(1)
        expect(wrapper.emitted().selected[0]).toEqual([1])
    })

    it('closeMobileMenu', () => {
        expect(wrapper.emitted().closeMobileMenu).toEqual(undefined)
        wrapper.vm.closeMobileMenu()
        expect(wrapper.emitted().closeMobileMenu[0]).toEqual([])
    })
    describe('admin sections access', () => {
        const buListAccess = () =>
            wrapper.vm.menuSections[0].links[1].authorized
        const orderBillAccess = () =>
            wrapper.vm.menuSections[0].links[2].authorized

        describe('without bu selected', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, true, true],
                    [withAdminProfile, true, true],
                    [withBUAdminProfile, false, true],
                    [withFleetProfile, false, true],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        businessUnitStore.businessUnit = null
                        setRights(right)
                        expect(buListAccess()).toEqual(hasBuListAccess)
                        expect(orderBillAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })
        describe('with bu selected', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, false, true],
                    [withAdminProfile, false, true],
                    [withBUAdminProfile, false, true],
                    [withFleetProfile, false, true],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        businessUnitStore.businessUnit = filledBU
                        setRights(right)
                        expect(buListAccess()).toEqual(hasBuListAccess)
                        expect(orderBillAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })

        describe('with hasFilled3OnboardingState falsy', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, false, true],
                    [withAdminProfile, false, false],
                    [withBUAdminProfile, false, false],
                    [withFleetProfile, false, false],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        businessUnitStore.businessUnit = filledBU
                        businessUnitStore.hasFilled3OnboardingState = jest
                            .fn()
                            .mockReturnValue(false)
                        setRights(right)
                        expect(buListAccess()).toEqual(hasBuListAccess)
                        expect(orderBillAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })
    })
    describe('other sections access', () => {
        const OrganizationParameterAccess = () =>
            wrapper.vm.menuSections[4].links[0].authorized
        const BUParameterAccess = () =>
            wrapper.vm.menuSections[4].links[1].authorized
        const userAccess = () => wrapper.vm.menuSections[4].links[2].authorized

        describe('without bu selected', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false, false],
                    [withSuperAdminProfile, true, false, true],
                    [withAdminProfile, true, false, true],
                    [withBUAdminProfile, false, false, true],
                    [withFleetProfile, false, false, false],
                    [withSupervisionProfile, false, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (
                        right,
                        hasOrganizationParameterAccess,
                        hasBUParameterAccess,
                        hasUserAccess
                    ) => {
                        businessUnitStore.businessUnit = null
                        setRights(right)
                        expect(OrganizationParameterAccess()).toEqual(
                            hasOrganizationParameterAccess
                        )
                        expect(BUParameterAccess()).toEqual(
                            hasBUParameterAccess
                        )
                        expect(userAccess()).toEqual(hasUserAccess)
                    }
                )
            })
        })
        describe('with bu selected', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, true, true],
                    [withAdminProfile, true, true],
                    [withBUAdminProfile, true, true],
                    [withFleetProfile, false, false],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        businessUnitStore.businessUnit = filledBU
                        setRights(right)
                        expect(BUParameterAccess()).toEqual(hasBuListAccess)
                        expect(userAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })

        describe('with hasFilled3OnboardingState falsy', () => {
            describe('without access', () => {
                const matrixProfile = [
                    [withoutRight, false, false],
                    [withSuperAdminProfile, true, true],
                    [withAdminProfile, true, false],
                    [withBUAdminProfile, true, false],
                    [withFleetProfile, false, false],
                    [withSupervisionProfile, false, false]
                ]
                test.each(matrixProfile)(
                    'with role: %p',
                    (right, hasBuListAccess, hasOrderBillAccess) => {
                        businessUnitStore.hasFilled3OnboardingState = jest
                            .fn()
                            .mockReturnValue(false)
                        businessUnitStore.businessUnit = filledBU
                        setRights(right)
                        expect(BUParameterAccess()).toEqual(hasBuListAccess)
                        expect(userAccess()).toEqual(hasOrderBillAccess)
                    }
                )
            })
        })
    })
})
