
import Vue from 'vue'

export default Vue.extend({
    name: 'IconPlusCircle',
    props: {
        isSolid: {
            type: Boolean,
            default: false
        }
    }
})
