import { defineStore } from 'pinia'
import { ref } from 'vue'
import { convertDate } from './charge'
import { FilterList } from '@/interfaces/filters'
import { Mileage, Vehicle, VehicleJSON } from '@/interfaces/vehicle'

const useVehicleStore = defineStore('vehicle', () => {
    const defaultVehicle = {
        id: null,
        immatriculation: '',
        additionalInformation: '',
        version: {
            id: 0,
            name: ''
        },
        brand: {
            id: 0,
            name: ''
        },
        model: {
            id: 0,
            name: ''
        },
        passes: [],
        lastMileage: null,
        dateCreated: null,
        dateUpdated: null
    }

    const vehicleEdited = ref<Vehicle>(defaultVehicle)
    const mileageEdited = ref<null | number>(null)

    const resetVehicleEdited = () => {
        vehicleEdited.value = defaultVehicle
        mileageEdited.value = null
    }

    const formatVehicle = (vehicle: Vehicle) => {
        const vehicleFormatted = {
            immatriculation: vehicle.immatriculation,
            additional_information: vehicle.additionalInformation,
            version_id: vehicle.version?.id || null,
            rfid_passes: vehicle.passes.map((p) => p.id),
            mileage: mileageEdited.value
        }
        return vehicleFormatted
    }

    const getVehicles = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            filters: FilterList
        }
    ): Promise<Vehicle[]> => {
        const { idOrga, idBU, filters } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const keyword = filters.searchField || (null as null | string)
        const orderBy = filters.orderBy || (null as null | string)
        let params = {
            offset,
            limit,
            keyword,
            orderBy,
            has_business_pass: filters.hasBusinessPass,
            has_domestic_zone: filters.hasDomesticZone,
            min_date_created: undefined as string | undefined | null,
            max_date_created: undefined as string | undefined | null
        }

        if (filters.dateRange) {
            const { minStartDate, maxStartDate } = convertDate(
                filters.dateRange[0],
                filters.dateRange[1]
            )
            if (minStartDate) {
                params = { ...params, min_date_created: minStartDate }
            }
            if (maxStartDate) {
                params = { ...params, max_date_created: maxStartDate }
            }
        }

        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles`
            context.$axios
                .$get(url, { params })
                .then((res: VehicleJSON[]) => {
                    const vehicles = context.$toCamel(res)
                    return resolve(vehicles)
                })
                .catch((e: any) => reject(e))
        })
    }

    const getVehicle = (
        context: any,
        payload: { idOrga: number; idBU: number; idVehicle: number }
    ): Promise<Vehicle> => {
        const { idOrga, idBU, idVehicle } = payload
        return new Promise((resolve, reject) => {
            const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
            context.$axios
                .$get(url)
                .then((res: VehicleJSON) => {
                    const vehicle = context.$toCamel(res)
                    resolve(vehicle)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const createVehicle = (
        context: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) => {
        const { vehicle, idOrga, idBU } = payload
        const vehicleFormatted = formatVehicle(vehicle)

        return new Promise((resolve, reject) => {
            context.$axios
                .$post(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles`,
                    vehicleFormatted
                )
                .then((res: VehicleJSON) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const updateVehicle = (
        context: any,
        payload: { vehicle: Vehicle; idOrga: number; idBU: number }
    ) => {
        const { vehicle, idOrga, idBU } = payload
        const vehicleFormatted = formatVehicle(vehicle)

        return new Promise((resolve, reject) => {
            context.$axios
                .$put(
                    `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${vehicle.id}`,
                    vehicleFormatted
                )
                .then((res: VehicleJSON) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const deleteVehicle = (
        context: any,
        payload: { idVehicle: number; idOrga: number; idBU: number }
    ) => {
        const { idVehicle, idOrga, idBU } = payload
        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}`
        return new Promise((resolve, reject) => {
            context.$axios
                .$delete(url)
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const getBrands = (context: any, payload: { searchField: string }) => {
        const params = {
            keyword: payload.searchField
        }
        const url = `/cloudapi/vehicles/brands`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const getModels = (
        context: any,
        payload: { idBrand: number; searchField: string }
    ) => {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const getVersions = (
        context: any,
        payload: { idBrand: number; idModel: number; searchField: string }
    ) => {
        const params = {
            keyword: payload.searchField
        }
        const { idBrand, idModel } = payload
        const url = `/cloudapi/vehicles/brands/${idBrand}/models/${idModel}/versions`
        return new Promise((resolve, reject) => {
            context.$axios
                .$get(url, { params })
                .then((res: any) => {
                    resolve(res)
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    const fetchMileageReports = (
        context: any,
        payload: {
            idOrga: number
            idBU: number
            idVehicle: number
            filters: FilterList
        }
    ): Promise<{ items: Mileage[]; totalCount: number }> => {
        const { idOrga, idBU, filters, idVehicle } = payload
        const offset =
            (filters.pages.index - 1) * filters.pages.perPage || (0 as number)
        const limit = filters.pages.perPage || (20 as number)
        const params = {
            offset,
            limit
        }

        const url = `/cloudapi/organizations/${idOrga}/business-units/${idBU}/vehicles/${idVehicle}/mileage-reports`
        return new Promise((resolve, reject) => {
            context.$axios
                .get(url, { params })
                .then((res: any) => {
                    const items = context.$toCamel(res.data.items)
                    const totalCount = parseInt(
                        res.headers['x-total-count'],
                        10
                    )
                    return resolve({ items, totalCount })
                })
                .catch((e: any) => {
                    reject(e)
                })
        })
    }

    return {
        getVehicles,
        getVehicle,
        createVehicle,
        updateVehicle,
        deleteVehicle,
        getBrands,
        getModels,
        getVersions,
        resetVehicleEdited,
        fetchMileageReports,
        vehicleEdited,
        mileageEdited
    }
})

type VehicleStore = Omit<
    ReturnType<typeof useVehicleStore>,
    keyof ReturnType<typeof defineStore>
>

export default useVehicleStore

export type { VehicleStore }
