export function isValidPrice(price: string): boolean {
    return /^$|^\d{0,2}(\.|,)?\d{0,5}$/.test(price)
}

export function isValidPower(power: string): boolean {
    return /^$|^\d{0,4}(\.|,)?\d{0,2}$/.test(power)
}

export function isValidHour(hour: string): boolean {
    return /^$|^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/.test(hour)
}
