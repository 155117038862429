
import _ from 'lodash'
import Vue from 'vue'
import { mapState } from 'pinia'
import Cookies from 'js-cookie'
import MenuAdminSection from '~/components/UI/menu/MenuAdminSection.vue'
import MenuUserSection from '~/components/UI/menu/MenuUserSection.vue'
import Logo from '~/components/Logo.vue'
import NewsletterSubscriptionModal from '~/components/others/NewsletterSubscriptionModal.vue'
import IconSpeakerPhone from '~/components/UI/icons/IconSpeakerPhone.vue'
import IconBusiness from '~/components/UI/icons/layout/IconBusiness.vue'
import IconLibrary from '~/components/UI/icons/cloud/IconLibrary.vue'
import IconBack from '~/components/UI/icons/layout/IconBack.vue'
import IconArrowRight from '~/components/UI/icons/layout/IconArrowRight.vue'
import IconSubitem from '~/components/UI/icons/dropdown/IconSubitem.vue'
import IconSearch from '~/components/UI/icons/input/IconSearch.vue'
import IconClear from '~/components/UI/icons/input/IconClear.vue'
import { BusinessUnit } from '~/interfaces/businessunit'
import useBusinessUnitStore from '~/store/businessunit'
import { UserOrganization } from '~/interfaces/organization'
import useOrganizationStore from '~/store/organization'
import useSessionStore from '~/store/session'

interface OptionListBuAvailable {
    id: number
    name: string
    type: number
    idOrga?: number
}
export default Vue.extend({
    name: 'AppMenu',
    components: {
        Logo,
        IconLibrary,
        IconBusiness,
        IconBack,
        IconArrowRight,
        IconSubitem,
        IconClear,
        IconSearch,
        MenuAdminSection,
        MenuUserSection,
        IconSpeakerPhone,
        NewsletterSubscriptionModal
    },
    props: {
        openMenu: {
            type: String,
            default: 'administration'
        }
    },
    data: () => ({
        selectedMenu: 'administration',
        displayAdministration: true,
        displaySelectBU: false,
        searchBU: '',
        showNewsletterModal: false
    }),
    computed: {
        ...mapState(useOrganizationStore, ['organization']),
        businessUnit(): BusinessUnit | null {
            return useBusinessUnitStore()?.businessUnit || null
        },
        ...mapState(useSessionStore, [
            'user',
            'countOrganizations',
            'homepage',
            'isSuperAdmin',
            'isAdmin'
        ]),
        currentLocale(): string {
            return this.$i18n.locale || 'en'
        },
        newsletterSubscriptionIsDelayed(): boolean {
            if (process.client) {
                return Cookies.get('delayed-newsletter') === 'true'
            }
            return false
        },
        isSubscribedToNewsletter(): boolean {
            return this.user?.isSubscribedToNewsletter || false
        },
        businessUnitsAvailable(this: any) {
            const options = [] as OptionListBuAvailable[]
            const organizationList = this.user?.rights?.organizations
            if (organizationList?.length > 0) {
                organizationList.forEach((org: any) => {
                    options.push({
                        id: org.id,
                        name: org.name,
                        type: 1
                    })
                    if (org.businessUnits) {
                        org.businessUnits.forEach((bu: BusinessUnit) => {
                            const pattern = `${_.deburr(this.searchBU)}`
                            const isBUSearched = (buSearch: BusinessUnit) =>
                                _.deburr(buSearch.name).match(
                                    new RegExp(pattern, 'i')
                                )
                            if (this.searchBU === '' || isBUSearched(bu)) {
                                options.push({
                                    id: bu.id,
                                    name: bu.name,
                                    type: 2,
                                    idOrga: org.id
                                })
                            }
                        })
                    }
                })
            }
            return options
        },
        canSwitchBU(): boolean {
            return (
                this.isSuperAdmin ||
                this.isAdmin ||
                this.countOrganizations > 1 ||
                (this.organization &&
                    (this.organization as UserOrganization)?.businessUnits
                        ?.length > 1)
            )
        },
        userInitials(this: any): string {
            const { firstName, lastName } = this.user

            if (!firstName || !lastName) {
                return ''
            }
            return firstName.charAt(0) + lastName.charAt(0)
        },
        transitionName(): string {
            return this.displaySelectBU ||
                (this.displayAdministration && this.organization)
                ? 'slide-fade-right'
                : 'slide-fade-left'
        },
        isProductionEnvironment(): boolean {
            return this.$config.NUXT_ENV === 'production'
        }
    },
    watch: {
        $route: {
            handler(this: any, val) {
                const { idOrga } = val.params
                if (!idOrga && this.isSuperAdmin)
                    this.toggleAdministration(true)
            },
            deep: true
        }
    },
    mounted(this: any) {
        this.displayAdministration = this.isSuperAdmin
        this.selectedMenu = this.openMenu
        this.$menu.$on('toggleAdministration', (force: boolean) => {
            this.toggleAdministration(force)
        })
    },
    beforeDestroy(this: any) {
        if (this.isSuperAdmin) {
            this.$menu.$off('toggleAdministration')
        }
    },
    methods: {
        updateModalIsVisible(value: boolean) {
            if (!value)
                Cookies.set('delayed-newsletter', 'true', {
                    expires: 365
                })
            this.showNewsletterModal = value
        },
        goToAdmin(this: any) {
            this.toggleAdministration(true)
            this.$router.push('/admin/organizations')
        },
        toggleAdministration(this: any, forceDisplay: boolean) {
            this.displayAdministration = forceDisplay
            if (this.displayAdministration) {
                this.switchMenu('administration')
                useOrganizationStore()
                    .setOrganization(null)
                    .then(() => {
                        useBusinessUnitStore()
                            .setBusinessUnit(null)
                            .then(() => {
                                if (!this.isSuperAdmin) {
                                    this.$router.push(this.homepage)
                                }
                            })
                    })
            }
        },
        toggleMenuOnHomePageRedirect(this: any) {
            if (!this.isSuperAdmin && useOrganizationStore().organization) {
                if (this.isAdmin) {
                    this.switchOrganization(this.organization.id)
                }
            } else {
                this.toggleAdministration(true)
            }
        },
        switchMenu(id: string) {
            this.selectedMenu = id
            this.$emit('change', id)
        },
        closeMobileMenu() {
            this.$emit('close')
        },
        switchBusinessUnit(idBU: number, idOrga: number) {
            this.$router.push(
                `/organizations/${idOrga}/business-units/${idBU}/dashboard`
            )

            if (idOrga !== this.organization?.id) {
                return useOrganizationStore()
                    .switchOrganization(this, {
                        organizationId: idOrga
                    })
                    .then(() => {
                        this.displaySelectBU = false
                    })
                    .catch((e) => {
                        this.$toast?.global.error({
                            title: this.$tc('errors.oops'),
                            message: e.statusText
                        })
                        this.$router.push('/')
                    })
            }
            return useBusinessUnitStore()
                .switchBusinessUnit(this, { idBU, idOrga })
                .then(() => {
                    this.displaySelectBU = false
                })
                .catch((e) => {
                    this.$toast?.global.error({
                        title: this.$tc('errors.oops'),
                        message: e.statusText
                    })
                    this.$router.push('/')
                })
        },
        switchOrganization(idOrganization: number) {
            return useOrganizationStore()
                .switchOrganization(this, {
                    organizationId: idOrganization
                })
                .then(() => useBusinessUnitStore().setBusinessUnit(null))
                .then(() => {
                    if (this.isSuperAdmin || this.isAdmin) {
                        this.$router.push(
                            `/organizations/${idOrganization}/dashboard`
                        )
                    } else {
                        this.$router.push(
                            `/organizations/${idOrganization}/business-units`
                        )
                    }
                    this.displaySelectBU = false
                })
                .catch((e) => {
                    this.$toast?.global.error({
                        title: this.$tc('errors.oops'),
                        message: e.statusText
                    })
                })
        },
        changeBusinessUnit(
            this: any,
            type: number,
            id: number,
            idOrga: number | undefined
        ) {
            const hasOrganizationSelected =
                type === 1 && (this.isSuperAdmin || this.isAdmin)
            const hasBusinessUnitSelected = type === 2
            if (hasBusinessUnitSelected) {
                this.switchBusinessUnit(id, idOrga)
            } else if (hasOrganizationSelected) {
                this.switchOrganization(id)
            }
        },
        openHelpdocs() {
            const windowHDLD = window as any
            if (windowHDLD.Lighthouse) {
                windowHDLD.Lighthouse.showButton()
                windowHDLD.Lighthouse.show()
            } else {
                window.open(
                    'https://support.chargemap.com/l/fr/category/j24pjsgdup-chargemap-business',
                    '_blank',
                    'noopener'
                )
            }
        }
    }
})
