var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"ChargemapSelectRef",staticClass:"chargemap-select flex flex-col space-y-1",attrs:{"data-testid":"chargemap-select"}},[(_vm.$slots.default)?_c('label',{staticClass:"block text-sm leading-5 font-medium",attrs:{"id":"listbox-label"},on:{"click":_vm.toggleOpen}},[_vm._t("default")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative",class:[_vm.large ? 'h-13' : 'flex-1']},[_c('span',{staticClass:"inline-block w-full h-full rounded-lg shadow-sm"},[(_vm.hasSearch && _vm.isOpen)?_c('div',{class:[!_vm.isValid ? 'border-red-300' : 'border-gray-300'],attrs:{"aria-expanded":_vm.isOpen}},[_c('AppInput',{attrs:{"id":"search","is-focus":"","placeholder":_vm.$t('select.search'),"no-x":"","data-testid":"search-input"},on:{"input":_vm.updateKeyword},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_vm._v(" "),_c('span',{staticClass:"absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"},[_c('IconChevronDown',{staticClass:"transition-transform text-gray-500 w-5 h-5",class:[
                            !_vm.isValid ? 'text-red-300' : 'text-gray-500',
                            _vm.isOpen &&
                                'rotate-180 duration-200 transform-gpu'
                        ]})],1)],1):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasSearch || !_vm.isOpen),expression:"!hasSearch || !isOpen"}],staticClass:"cursor-pointer relative w-full rounded-lg border pl-3 pr-10 py-2.5 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:leading-5 text-sm min-h-10",class:[
                    _vm.isDisabled || _vm.loading ? 'bg-gray-50' : 'bg-white',
                    !_vm.isValid ? 'border-red-300' : 'border-gray-300'
                ],attrs:{"type":"button","disabled":_vm.isDisabled || _vm.loading,"aria-expanded":_vm.isOpen,"aria-labelledby":"listbox-label"},on:{"click":_vm.toggleOpen}},[(_vm.loading)?_c('IconLoader',{staticClass:"h-5 w-5 animate-spin"}):_c('span',{staticClass:"block truncate",class:_vm.displayValue === _vm.placeholder && 'text-gray-500'},[_vm._v("\n                    "+_vm._s(_vm.displayValue)+"\n                ")]),_vm._v(" "),_c('span',{staticClass:"absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"},[_c('IconChevronDown',{staticClass:"transition-transform text-gray-500 w-5 h-5",class:[
                            !_vm.isValid ? 'text-red-300' : 'text-gray-500',
                            _vm.isOpen &&
                                'rotate-180 duration-200 transform-gpu'
                        ]})],1)],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"absolute w-full rounded-b-md overflow-hidden bg-white shadow-lg z-50",class:[
                _vm.position === 'top' ? 'top-auto bottom-full mb-1' : ' mt-1'
            ]},[_c('ul',{staticClass:"max-h-60 p-2 flex flex-col gap-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5",attrs:{"tabindex":"-1","role":"listbox","aria-labelledby":"listbox-label","aria-activedescendant":"listbox-item-0"}},[(
                        _vm.hasSearch &&
                        (_vm.canCreateOption || _vm.customInfoSearchText)
                    )?_c('li',{staticClass:"cursor-default rounded relative text-left py-2 pl-2 pr-2 text-sm font-medium text-gray-500"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                            _vm.canCreateOption
                                ? 'select.select_or_create'
                                : _vm.customInfoSearchText
                        ))+"\n                ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredValues),function(opt,index){return _c('li',{key:opt.id,staticClass:"cursor-default rounded relative text-left p-2.5 hover:bg-gray-50 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-50",class:[
                        opt.id === _vm.value && 'bg-gray-50',
                        opt.blocked
                            ? 'cursor-not-allowed text-gray-400'
                            : 'cursor-pointer text-gray-900'
                    ],attrs:{"id":`listbox-item-${index}`,"role":"option"},on:{"click":function($event){return _vm.onSelect(opt)}}},[(_vm.multiple && Array.isArray(_vm.value))?_c('span',{staticClass:"truncate gap-2 flex items-center"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.value.includes(opt.id)}}),_vm._v("\n                        "+_vm._s(opt.value)+"\n                    ")]):_c('span',{staticClass:"truncate flex justify-between items-center"},[_vm._v("\n                        "+_vm._s(opt.value)+"\n                        "),(opt.id === _vm.value)?_c('IconCheck',{staticClass:"w-5 h-5 text-primary-600"}):_vm._e()],1)])}),_vm._v(" "),(
                        !_vm.keywordIsSameAsValue && _vm.keyword && _vm.canCreateOption
                    )?_c('li',{staticClass:"cursor-pointer rounded relative text-left py-2 pl-2 pr-2 hover:bg-gray-50 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-50",attrs:{"role":"option"},on:{"click":function($event){return _vm.onSelect({
                            id: 0,
                            value: _vm.keyword,
                            blocked: false
                        })}}},[_c('div',{staticClass:"flex gap-1 items-center"},[_vm._v("\n                        "+_vm._s(_vm.$t('select.create'))+"\n                        "),_c('AppBadge',{staticClass:"mr-2 align-text-bottom pr-2.5",attrs:{"size":"xs","color":"green"}},[_vm._v("\n                            "+_vm._s(_vm.keyword)+"\n                        ")])],1)]):_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }