import { createLocalVue, shallowMount } from '@vue/test-utils'
// @ts-ignore
import ChargemapSelect from '@/components/UI/CMUI/ChargemapSelect.vue'
import basicMountWrapper from '~/utilitary/mountWrapper'

const optionsList = [
    {
        id: 1,
        value: 'test',
        blocked: false
    },
    {
        id: 2,
        value: 'test2',
        blocked: true,
        raw: {
            id: 2,
            name: 'test2'
        }
    },
    {
        id: 3,
        value: 'test3',
        blocked: true
    },
    {
        id: 4,
        value: 'test4',
        blocked: false
    }
]

const localVue = createLocalVue()

const propsData = {
    value: '',
    options: optionsList
}
describe('components/UI/CMUI/ChargemapSelect.vue', () => {
    let wrapper: any

    beforeEach(() => {
        wrapper = shallowMount(ChargemapSelect, {
            ...basicMountWrapper,
            localVue,
            propsData
        })
    })

    it('is a Vue instance', () => {
        expect(wrapper.vm).toBeTruthy()
        const component = wrapper.find('[data-testid="chargemap-select"]')

        expect(component.exists()).toBeTruthy()
    })

    describe('methods', () => {
        it('onSelect emit input value', () => {
            wrapper.vm.isOpen = true
            expect(wrapper.emitted().input).toBeFalsy()
            let emitValue = optionsList[1]
            wrapper.vm.onSelect(emitValue)
            expect(wrapper.vm.isOpen).toBeTruthy()
            expect(wrapper.emitted()).toEqual({})

            emitValue = optionsList[0]
            wrapper.vm.onSelect(emitValue)
            expect(wrapper.vm.isOpen).toBeFalsy()
            expect(wrapper.emitted().input[0]).toEqual([emitValue.id])
        })

        it('toggle visibility', async () => {
            wrapper.vm.isOpen = false
            await wrapper.setProps({
                isDisabled: true
            })
            wrapper.vm.toggleOpen()
            expect(wrapper.vm.isOpen).toBeFalsy()
            await wrapper.setProps({
                isDisabled: false
            })
            wrapper.vm.toggleOpen()
            expect(wrapper.vm.isOpen).toBeTruthy()
        })

        it('display options from a list of objects without keys', () => {
            expect(wrapper.vm.displayOptions).toEqual([
                {
                    id: 1,
                    value: 'test',
                    blocked: false,
                    raw: null
                },
                {
                    id: 2,
                    value: 'test2',
                    blocked: true,
                    raw: {
                        id: 2,
                        name: 'test2'
                    }
                },
                {
                    id: 3,
                    value: 'test3',
                    blocked: true,
                    raw: null
                },
                {
                    id: 4,
                    value: 'test4',
                    blocked: false,
                    raw: null
                }
            ])
        })
    })

    describe('computed', () => {
        describe('filteredValues', () => {
            it('should return a filtered list based on the keyword', () => {
                wrapper.vm.keyword = '4'

                expect(wrapper.vm.filteredValues).toEqual([
                    {
                        id: 4,
                        value: 'test4',
                        blocked: false,
                        raw: null
                    }
                ])
            })

            it("should'nt return a filtered list when hasAsyncFetch is true", async () => {
                await wrapper.setProps({
                    hasAsyncFetch: true
                })
                wrapper.vm.keyword = '4'

                expect(wrapper.vm.filteredValues).toEqual(
                    wrapper.vm.displayOptions
                )
            })
        })
    })
})
