import useSessionStore from '~/store/session'

export default function (context) {
    const { route, $auth, redirect } = context

    const isLogin = /^(\/login)(.)*/.test(route.path)
    const isPasswordReset = /^(\/password-reset)(.)*/.test(route.path)

    if (!isLogin && !isPasswordReset) {
        const hasTokenValid = $auth?.strategy?.token?.status()?.valid()
        const hasRefreshToken = $auth?.strategy?.refreshToken?.status()?.valid()
        const logout = () =>
            useSessionStore()
                .logout(context)
                .finally(() => redirect('/login#session-expired'))
        if (!hasTokenValid) {
            if (!hasRefreshToken) {
                $auth.$storage.setUniversal('redirect', route.path)
                return logout()
            }
            return $auth
                .refreshTokens()
                .then(() => {})
                .catch(() => logout())
        }
    }
}
